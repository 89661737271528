import React, { Fragment } from 'react';
import {
  growFlowCalendlyBookingAvailable,
  growFlowCalendlyBookingAvailableForUnconfiguredState,
  onlyRegisteringForRetail as retailOnlyBooking,
} from '../../../utilities/workflowCriteria';
import Calendly from '../../../components/widgets/Calendly';
import { WizardLayout } from 'components';
import congratsIcon from '../../../images/congrats-icon.png';
import { createQueriesHook as createIntroQueries } from '../../../utilities/introInformation';
import { createQueriesHook as createLicenseQueries } from '../../../utilities/licenses';
import { getStepCompletionPercentage } from '../../../utilities/steps';
import { stateLookup } from '../../../data/StateOptions';
import { useTracking } from '../../../utilities/hooks';

const stateOptionsForUnconfiguredState = () => stateLookup('ok');

const CompletionPage = () => {
  const { totalPercentage: percentageComplete } = getStepCompletionPercentage();
  const introQueries = createIntroQueries();
  const licenses = createLicenseQueries();
  const complete = percentageComplete === 100;

  const stateOptions = stateLookup(introQueries.state);

  const allAttributesToInspect = {
    ...stateOptions,
    ...licenses,
    ...introQueries,
  };

  const growFlowAppointmentSection = growFlowCalendlyBookingAvailable(
    allAttributesToInspect
  ) ? (
    <Calendly calendlyUrl={stateOptions.calendlyUrl} />
  ) : null;

  const growFlowAppointmentSectionForUnconfiguredState = growFlowCalendlyBookingAvailableForUnconfiguredState(
    allAttributesToInspect
  ) ? (
    <Calendly calendlyUrl={stateOptionsForUnconfiguredState().calendlyUrl} />
  ) : null;

  const retailAppointmentSection = retailOnlyBooking(allAttributesToInspect) ? (
    <Calendly calendlyUrl="https://calendly.com/growflow-customer-activation/retail" />
  ) : null;

  const completeSection = complete ? (
    <Fragment>
      <div className="celebration">
        <img className="icon" src={congratsIcon} />

        <h1>You did it! Account setup complete</h1>

        <p>
          A growflow rep will make sure that everything is perfect in your account and
          then reach out to you directly to verify at:
        </p>

        <strong>{introQueries.email}</strong>
      </div>

      {growFlowAppointmentSection}

      {growFlowAppointmentSectionForUnconfiguredState}

      {retailAppointmentSection}
    </Fragment>
  ) : null;

  const incompleteSection = complete ? null : (
    <h2>Uh oh! You've still got a few steps to go</h2>
  );

  useTracking('Completion');

  return (
    <WizardLayout>
      {completeSection}

      {incompleteSection}
    </WizardLayout>
  );
};

export default CompletionPage;
